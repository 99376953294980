<template>
  <bs-popup id="mapping-aircraft-popup" @on-popup-closed="onPopupClosed" :auto-show="true" ref="form">
    <template v-slot:title>Base Price Profile</template>
    <template v-slot:content>
      <bs-error-message :errors="errorMessages"/>
      <div class="mb-3">
        <label for="nama" class="form-label">Nama</label>
        <bs-textbox v-model="model.nama" />
        <bs-input-error :errors="errorMessages" keyError="Nama" />
      </div>

      <div class="mb-3">
        <label for="timeRange" class="form-label">Tipe Waktu</label>
        <time-range-combo v-model="model.timeRangeId" :textDefault="model.timeRangeNama"/>
        <bs-input-error :errors="errorMessages" keyError="Tipe Waktu"/>
      </div>

      <div class="mb-3" v-show="model.timeRangeId===1">
        <label for="startDay" class="form-label">Hari Mulai</label>
        <start-day-combo v-model="model.startDay" :text-default="model.startDayName"/>
        <bs-input-error :errors="errorMessages" keyError="Hari Mulai"/>
      </div>

      <div class="mb-3">
        <label for="location" class="form-label">Lokasi</label>
        <location-combo v-model="model.locationId" :textDefault="model.locationCode"/>
        <bs-input-error :errors="errorMessages" keyError="Lokasi"/>
      </div>

      <div class="mb-3">
        <label for="currency" class="form-label">Mata Uang</label>
        <currency-combo v-model="model.currencyId" :textDefault="model.currencyCode"/>
        <bs-input-error :errors="errorMessages" keyError="Mata Uang"/>
      </div>

      <div class="mb-3">
        <label for="unit" class="form-label">Satuan Unit</label>
        <unit-combo v-model="model.unitId" :textDefault="model.unitCode"/>
        <bs-input-error :errors="errorMessages" keyError="Satuan Unit"/>
      </div>

    </template>
    <template v-slot:footer>
      <button class="btn btn-sm btn-primary" @click="saveData"><i class="bi bi-check-circle"></i> Save</button>
      <button class="btn btn-sm btn-secondary" @click="close"><i class="bi bi-x-circle"></i> Cancel</button>
    </template>
  </bs-popup>
</template>

<script>
import BasePriceProfileService from "@/services/base-price-profile-service";
import TimeRangeCombo from "@/components/TimeRangeCombo.vue";
import LocationCombo from "@/components/LocationCombo";
import StartDayCombo from "@/components/StartDayCombo.vue";
import CurrencyCombo from "@/components/CurrencyCombo.vue";
import UnitCombo from "@/components/UnitCombo.vue";


export default {
  components: {
    TimeRangeCombo,
    StartDayCombo,
    LocationCombo,
    CurrencyCombo,
    UnitCombo
  },
  data: function () {
    return {
      id: 0,
      model: BasePriceProfileService.default(),
      errorMessages: null
    }
  },
  mounted() {
    let id = this.$route.params.id;
    if (id !== undefined && id !== null && id !== 0) {
      this.id = id;
      this.loadData();
    }
  },
  methods: {
    async loadData() {
      if (this.id === undefined || this.id === null || this.id === 0) {
        return;
      }

      const result = await BasePriceProfileService.getObject(this.id);
      if (result.status) {
        this.errorMessages = result.errorMessages;
      } else {
        this.errorMessages = null;
      }

      this.model = result.data;
    },
    async onPopupClosed() {
      this.$router.push('/base-price-profile');
    },
    async close() {
      this.$refs.form.closePopup();
    },
    async saveData() {
      try {
        this.$store.commit('progressCounterQueueIncrement');
        let status = false;
        if (this.model.timeRangeId===1){
          this.model.startDay = null;
        }
        if (this.id !== undefined && this.id !== null && this.id !== 0) {
          const result = await BasePriceProfileService.update(this.model, this.id);
          if (this.model.timeRangeId!==1){
            this.model.startDay = null;
          }
          status = result.status;
          if (status) {
            this.errorMessages = null;
          } else {
            this.errorMessages = result.errorMessages;
          }
        } else {
          const result = await BasePriceProfileService.create(this.model);
          status = result.status;
          console.log('save status', status);
          if (status) {
            this.errorMessages = null;
            this.id = result.data.id;
          } else {
            this.errorMessages = result.errorMessages;
          }
        }

        if (!status) return;

        this.$refs.form.closePopup();

        this.$router.push(`/base-price-profile/detail/${this.id}`);


      } finally {
        this.$store.commit('progressCounterQueueDecrement');
      }
    }
  }
}
</script>